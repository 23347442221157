import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import * as React from "react";
import { Field, Form } from "react-final-form";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import styles from "./orderModal.module.scss";

import { FormHelperText, Grid, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchCreateOrder, fetchOrders } from "../../../../redux/slices/orderSlice";
import { fetchProducts } from "../../../../redux/slices/productSlice";
import StickyHeadTable from "../table";

const OrderModal = ({ open = false, onClose }) => {
  const Swal = require("sweetalert2");

  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [errorMesage, setErrorMesage] = useState("");

  const { products } = useSelector((state) => state.products);

  const dispatch = useDispatch();

  const handleSubmit = async (data) => {

    data.products = selectedProducts;
    try {
      dispatch(fetchCreateOrder(data));
      onClose();
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Yeni Məhsul Əlavə Edildi`,
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(fetchOrders());
    } catch (error) {
      alert("sehv");
    }
  };

  const handleSubmiteProducts = (data) => {
    setErrorMesage(" ");
    const productId = data.productId.id;
    const existingProduct = selectedProducts.find((product) => product.productId === productId);

    if (existingProduct) {
      setErrorMesage("Bu Məhsul Artıq Əlavə Edilib");
      return;
    }

    const productData = {
      productId,
      name: data.productId.name,
      quantity: parseInt(data.quantity, 10),
    };

    setSelectedProducts([...selectedProducts, productData]);
  };

  const deleteSelectedProduct = (id) => {
    let updatedSelectedProducts = selectedProducts.filter((product) => product.productId !== id);

    setSelectedProducts(updatedSelectedProducts);
  };

  useEffect(() => {
    dispatch(fetchProducts({ id: null, name: "" }));
    setSelectedProducts([]);
  }, [open]);

  return (
    <div className={styles.wrapper} style={open === false ? { display: "none" } : {}}>
      <div className="modal">
        <div className={styles.formContainer}>
          <Form
            onSubmit={handleSubmiteProducts}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={4}
                  justifyContent="space-between"
                  alignItems="end"
                  //    alignContent="center"
                  //alignSelf="center"
                >
                  <Grid item xl={8} xs={6}>
                    <h3>Məhsullar</h3>
                    <Field name="productId">
                      {({ input }) => (
                        <>
                          <Select
                            InputProps={{}}
                            required
                            style={{ width: "100%" }}
                            name={input.name}
                            value={input.value}
                            onChange={(e) => input.onChange(e.target.value)}
                          >
                            {products?.map((item) => (
                              <MenuItem key={item?.id} value={item}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error>{errorMesage}</FormHelperText>
                        </>
                      )}
                    </Field>
                  </Grid>
                  <Grid item className={styles.addProduct} xl={2} xs={3}>
                    <h3>Sayi</h3>
                    <Field required name="quantity" component="input" min="1" type="number" defaultValue={1} />
                  </Grid>
                  <Grid item className={styles.addProduct} xl={2} xs={3}>
                    <Button variant="outlined" type="submit">
                      OK
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xl={12} xs={12}>
                  {selectedProducts.length > 0 && (
                    <StickyHeadTable products={selectedProducts} deleteBtn={deleteSelectedProduct} />
                  )}
                </Grid>
              </form>
            )}
          />
          <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1} justifyContent="space-between">
                  <div className={styles.close_root}>
                    <Button variant="contained" className={styles.closeBtn} onClick={() => onClose()}>
                      <AiOutlineClose />
                    </Button>
                  </div>
                  <Grid item xl={6} xs={12}>
                    <h3>Müştərinin Adı və Soyadı</h3>
                    <Field required name="customer" component="input" placeholder="Adı" />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <h3>Telefon</h3>
                    <Field required name="phone" component="input" type="number" placeholder="Telefon" />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <h3>Müştərinin qeydi</h3>
                    <Field required name="customerNote" component="input" type="text" placeholder="Qeyd" />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <h3>Date</h3>
                    <Field required type="date" defaultValue={selectedDate} name="deliveryDate" component="input" />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <h3>Müştərinin Unvani</h3>
                    <Field required name="deliveryAddress" component="input" type="text" placeholder="Qeyd" />
                  </Grid>
                  <Grid container spacing={3} marginTop={2} marginLeft={3} className={styles.submite} justifyContent="space-between">
                    <Grid item xl={4} xs={6}>
                      <Button variant="outlined"  startIcon={<DeleteIcon />} onClick={form.reset}>
                        Təmizlə
                      </Button>
                    </Grid>
                    <Grid item xl={3} xs={6} alignItems="flex-end">
                      <Button type="submit" variant="contained" endIcon={<SendIcon />}>
                        Əlavə Et
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
