import { Field, Form } from 'react-final-form';
import styles from './Modal.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import { Grid, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { fetchCreateProduct, fetchProducts, fetchUpdateProduct } from '../../redux/slices/productSlice';
import { useNavigate } from 'react-router-dom';

const canUsedList = [
	{ value: 'forMen', label: 'Kişi' },
	{ value: 'forWomen', label: 'Qadın' },
	{ value: 'forChild', label: 'Uşaq' },
	{ value: 'forAdult', label: 'Yetişkinlər' },
	{ value: 'forAll', label: 'Hərkəs' }
];

const Modal = ({ open = false, onClose, product = null }) => {
	const Swal = require('sweetalert2');

	const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
	const [selectedFile, setSelectedFile] = useState(null);

	const { firmList } = useSelector(state => state.firmList);
	const { categories } = useSelector(state => state.categories);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleChange = e => {
		if (e.target.files.length > 0) {
			setSelectedFile(e.target.files[0]);
		}
	};

	const handleSubmit = async data => {
		data.Photo = selectedFile;
		if (product === null) {
			try {
				await dispatch(fetchCreateProduct(data));
				onClose();
				Swal.fire({
					position: 'center',
					icon: 'success',
					title: `Yeni Məhsul Əlavə Edildi`,
					showConfirmButton: false,
					timer: 2000
				});
				dispatch(fetchProducts({ id: null, name: '' }));
			} catch (error) {
				console.log(error);
			}
		} else {
			try {
				dispatch(fetchUpdateProduct({ id: product.id, formData: data }));
				onClose();
				Swal.fire({
					position: 'center',
					icon: 'success',
					title: `Məhsul Müvəffəqiətlə yeniləndi`,
					showConfirmButton: false,
					timer: 2000
				});
				navigate('/');
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		<div className={styles.wrapper} style={open === false ? { display: 'none' } : {}}>
			<div className='modal'>
				<Form
					initialValues={{
						canUsed: product?.canUsed ?? canUsedList[3],
						firmId: product?.firmId,
						categoryId: product?.categoryId,
						name: product?.name,
						weight: product?.weight,
						price: product?.price,
						priceOnFirm: product?.priceOnFirm,
						description: product?.description,
						storageCondition: product?.storageCondition,
						date: selectedDate,
						photo: product?.photo
					}}
					onSubmit={handleSubmit}
					render={({ handleSubmit, form }) => (
						<form onSubmit={handleSubmit}>
							<Grid container spacing={1} justifyContent='space-between'>
								<div className={styles.close_root}>
									<Button variant='contained' className={styles.closeBtn} onClick={() => onClose()}>
										<AiOutlineClose />
									</Button>
								</div>
								<Grid item xl={4} xs={12} md={5}>
									<h3>Firma</h3>
									<Field name='firmId'>
										{({ input }) => (
											<Select
												required
												style={{ width: '100%' }}
												id='firmId'
												name='firmId'
												placeholder='Firma'
												value={input.value}
												onChange={e => input.onChange(e.target.value)}
											>
												{firmList?.map(firm => (
													<MenuItem key={firm.id} value={firm.id}>
														{firm.name}
													</MenuItem>
												))}
											</Select>
										)}
									</Field>
								</Grid>
								<Grid item xl={4} xs={12} md={5}>
									<h3>Kateqoria</h3>
									<Field name='categoryId'>
										{({ input }) => (
											<Select
												required
												style={{ width: '100%' }}
												id='categoryId'
												name='categoryId'
												placeholder='Kateqoria'
												value={input.value}
												onChange={e => input.onChange(e.target.value)}
											>
												{categories?.map(category => (
													<MenuItem key={category.id} value={category.id}>
														{category.name}
													</MenuItem>
												))}
											</Select>
										)}
									</Field>
								</Grid>
								<Grid item xl={4} xs={12} md={2}>
									<h3>Istifadə</h3>
									<Field name='canUsed' type='select'>
										{({ input }) => (
											<Select
												required
												style={{ width: '100%' }}
												value={input.value}
												onChange={e => input.onChange(e.target.value)}
											>
												{canUsedList?.map(used => (
													<MenuItem key={used.label} value={used.value}>
														{used.label}
													</MenuItem>
												))}
											</Select>
										)}
									</Field>
								</Grid>
								<Grid item xl={6} xs={12} md={6}>
									<h3>Name</h3>
									<Field required name='name' component='input' placeholder='Adı' />
								</Grid>
								<Grid item xl={6} xs={12} md={6}>
									<h3>Weight</h3>
									<Field required name='weight' component='input' type='number' placeholder='Çəkisi' />
								</Grid>
								<Grid item xl={6} xs={12} md={6}>
									<h3>Price</h3>
									<Field required name='price' component='input' type='number' placeholder='Qiymət' />
								</Grid>
								<Grid item xl={6} xs={12} md={6}>
									<h3>PriceOnFirm</h3>
									<Field
										required
										name='priceOnFirm'
										component='input'
										type='number'
										placeholder='Firmada Qiymət'
									/>
								</Grid>

								<Grid item xl={12} xs={12}>
									<h3>Description</h3>

									<Field required name='description' component='input' placeholder='Məlumat' />
								</Grid>
								<Grid item xl={12} xs={12}>
									<h3>StorageCondition</h3>
									<Field required name='storageCondition' component='input' placeholder='Saxlanma' />
								</Grid>
								<Grid item xl={6} xs={12} md={6}>
									<h3>Date</h3>
									<Field required type='date' name='date' component='input' placeholder='Description' />
								</Grid>
								<Grid item xl={6} xs={12} md={6}>
									<h3>Image</h3>
									<Field required name='photo'>
										{props => (
											<div>
												<input
													name={props.input.name}
													className={styles.fileBtn}
													type='file'
													onChange={e => {
														props.input.onChange(e.target.files);
														handleChange(e);
													}}
													placeholder='Image'
													id='Files'
													multiple
												/>
											</div>
										)}
									</Field>
								</Grid>
								<Grid item xl={6} xs={12} md={6} marginTop={2}>
									<Button variant='outlined' fullWidth startIcon={<DeleteIcon />} onClick={form.reset}>
										Təmizlə
									</Button>
								</Grid>
								<Grid item xl={6} xs={12} md={6} marginTop={2}>
									<Button type='submit' fullWidth variant='contained' endIcon={<SendIcon />}>
										Əlavə Et
									</Button>
								</Grid>
							</Grid>
						</form>
					)}
				/>
			</div>
		</div>
	);
};

export default Modal;
