import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { MdDeleteForever } from "react-icons/md";

import styles from "./table.module.scss";

const columns = [
  { id: "name", label: "Ad", minWidth: 300 },
  {
    id: "quantity",
    label: "Say",
    minWidth: 10,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  { id: "say", label: "Sil", align: "center", minWidth: 10 },
];

export default function StickyHeadTable({ products, deleteBtn }) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }} className={styles.root}>
      <TableContainer sx={{ maxHeight: 250 }} className={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, maxHeight: "15px" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {products?.map((item) => (
              <TableRow key={item.productId}>
                <TableCell>{item.name}</TableCell>
                <TableCell align="center">{item.quantity}</TableCell>
                <TableCell align="center">
                  <Button variant="text" onClick={() => deleteBtn(item.productId)} className={styles.deleteBtn}>
                    <MdDeleteForever />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
