import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../../../redux/slices/productSlice";

export default function MobSelect({ data, title }) {
  const [age, setAge] = React.useState("");
  const firmId = localStorage.getItem("firmId");
  const categoryId = localStorage.getItem("categoryId");

  const dispatch = useDispatch();

  React.useEffect(() => {
    localStorage.removeItem("firmId");
    localStorage.removeItem("categoryId");
  }, []);

  const handleChange = (e) => {
    if (title === "Firma") {
      dispatch(
        fetchProducts({
          id: e.target.value,
          categoryId: categoryId ? categoryId : null,
        })
      );
      localStorage.setItem("firmId", e.target.value);
    } else {
      dispatch(
        fetchProducts({
          id: firmId ? firmId : null,
          categoryId: e.target.value,
        })
      );
      localStorage.setItem("categoryId", e.target.value);
    }
    setAge(e.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
          onChange={(e) => handleChange(e)}
        >
          <MenuItem value={null}>Bütün Məhsullar</MenuItem>
          {data &&
            data.map((obj) => (
              <MenuItem key={obj.id} value={obj.id}>
                {obj.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
