import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../api/config";
 
import { userLogin } from '../function/authAction';

const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null;


const initialState = {
  token: userToken,
  status: "loading",
  error: "",
  login: Boolean(userToken),
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('userToken');
      state.login = false;
      state.error = "";
      window.location.reload()
    },
  },

  extraReducers: {
    [userLogin.pending]: (state) => {
      state.login = false;
      state.error = "";
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.login = true;
      state.error = "";
      state.token = payload.data;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.error = payload.message;
    },
  },
});

export const {logout} = authSlice.actions;
export default authSlice.reducer;
