import React, { useState } from "react";
import { BiCheckboxChecked } from "react-icons/bi";
import { TbCurrencyManat } from "react-icons/tb";
import styles from "./select.module.scss";

import CurrencyLiraIcon from "@mui/icons-material/CurrencyLira";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const SalesSelect = ({ sales, handleUpdatesales }) => {
  const [showItems, setShowItems] = useState();

  const showsalesItems = (id) => {
    if (showItems === id) {
      setShowItems();
    } else {
      setShowItems(id);
    }
  };

  return (
    <div className={styles.root}>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Tarix</TableCell>
              <TableCell align="center">Cəmi Məbləğ</TableCell>
              <TableCell align="center">Satis Qiymeti</TableCell>
              <TableCell align="center">Mənfəət</TableCell>
              <TableCell align="center">Kredit</TableCell>
              <TableCell align="center">Qeyd</TableCell>
              <TableCell align="center">Ətraflı</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sales &&
              sales.map((sales) => (
                <>
                  <TableRow key={sales.id} sx={{ "&:last-child td, &:last-child th": { bsales: 0 } }}>
                    <TableCell className={styles.rowTable}>{sales.dateOfSale}</TableCell>
                    <TableCell align="center">
                      {sales.totalPrice} <TbCurrencyManat />
                    </TableCell>
                    <TableCell align="center">
                      {sales.priceOfSale} <TbCurrencyManat />
                    </TableCell>
                    <TableCell align="center">
                      {sales.profit} <TbCurrencyManat />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={styles.checked}
                      style={sales.isCredit ? { color: "red" } : { color: "green" }}
                    >
                      <BiCheckboxChecked  />
                    </TableCell>
                    <TableCell align="center">{sales.note}</TableCell>

                    {/* <TableCell align="center">
                      <Button
                        className={styles.checked}
                        disabled={sales.isDelivered}
                        onClick={() => handleUpdatesales(sales.id)}
                      >
                        <BiCheckboxChecked />
                      </Button>
                    </TableCell> */}
                    <TableCell align="center" className={styles.show_sales} onClick={() => showsalesItems(sales.id)}>
                      <KeyboardArrowDownIcon
                        style={showItems === sales.id ? { rotate: "360deg" } : { rotate: "180deg" }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableCell colSpan={8}>
                    <div
                      className={styles.salesProducts}
                      style={showItems === sales.id ? { height: 130 * sales.details.length } : { height: 0 }}
                    >
                      {sales.details.map((pr) => (
                        <div key={pr.productId} className={styles.product}>
                          <div className={styles.box_img}>
                            <img src={pr.product.thumbnail} alt="" />
                            <div className={styles.box_title}>
                              <p>{pr.product.name}</p>
                            </div>
                          </div>
                          <div className={styles.box_size}>
                            <p>{pr.product.weight} qram</p>
                          </div>
                          <div className={styles.box_count}>
                            <p>{pr.quantity} ədəd</p>
                          </div>
                          <div className={styles.box_price}>
                            <p>
                              {pr.product.price} <TbCurrencyManat />
                            </p>
                          </div>
                          <div className={styles.box_price}>
                            <p>
                              {pr.product.priceOnFirm} <CurrencyLiraIcon />
                            </p>
                          </div>
                        </div>
                      ))}
                      {/* <div className="sales_data my-2 d-flex justify-content-between">
                      <div className="left">
                        <h6>Full Name: {pr.createdBy}</h6>
                        <h6>Country: {item.country}</h6>
                      </div>
                      <div className="right">
                        <h6>Email: {pr.email}</h6>
                        <h6>Phone: {pr.phone}</h6>
                      </div>
                    </div> */}
                    </div>
                  </TableCell>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SalesSelect;
