import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import jwt_decode from "jwt-decode";
import * as React from "react";
import { useEffect, useState } from "react";
import { ImExit } from "react-icons/im";
import { BsFillPersonFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/image/logo.jpeg";
import styles from "./header.module.scss";
import { logout } from "../../redux/slices/autSlice";

const pages = ["Məhsullar", "Sifarişlər", "Stock", "Satış"];
//"Yolda Olan Stock",

function ResponsiveAppBar({ onClickAddProduct }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("userToken");
  const [decodeToken, setDecodeToken] = useState(null);

  useEffect(() => {
    if (token) {
      const decodedToken = jwt_decode(token);
      setDecodeToken(decodedToken);
      return;
    } else {
      setDecodeToken(null);
    }
  }, [token]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleExit = () => {
     dispatch(logout())
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src={logo}
            sx={{ display: { xl: "none", xl: "flex" }, mr: 1 }}
            className={styles.logo}
          />
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            YUMMY
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              className={styles.mobilMenu}
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {token && decodeToken?.role === "admin"
                ? pages.map((page, index) => (
                    <li key={index}>
                      <Link
                        to={"/" + page}
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        {page}
                      </Link>
                    </li>
                  ))
                : ""}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            YUMMY
          </Typography>
          <Box
            className={styles.menu}
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            {token && decodeToken?.role === "admin"
              ? pages.map((page, index) => (
                  <Link
                    key={index}
                    to={"/" + page}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {page}
                  </Link>
                ))
              : ""}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                {token ? (
                  <IconButton
                    style={{ color: "#ffffff", marginRight: "20px" }}
                    onClick={() => handleExit()}
                    sx={{ p: 0 }}
                  >
                    <ImExit />
                  </IconButton>
                ) : (
                  <IconButton
                    style={{ color: "#ffffff", marginRight: "20px" }}
                    onClick={() => navigate("/login")}
                    sx={{ p: 0 }}
                  >
                    <BsFillPersonFill />
                  </IconButton>
                )}
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
