import React, { useEffect, useState } from "react";
import OrderSelect from "./components/orderSelect";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders, updateOrderStatus } from "../../redux/slices/orderSlice";
import styles from "./orders.module.scss";
import Swal from "sweetalert2";
import { Button } from "@mui/material";
import OrderModal from "./components/orderModal";
import { useNavigate } from "react-router-dom";

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orders, error } = useSelector((state) => state.orders);
  const { login } = useSelector((state) => state.auth);

  const [modalDisplay, setModalDisplay] = useState(false);

  const handleUpdateOrder = (id) => {
    Swal.fire({
      title: "Məhsulun Təhvil Verildiyindən Əminsinizmi?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Bəli!",
      cancelButtonText: "Imtina",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await dispatch(updateOrderStatus(id));
        Swal.fire("Dəyişiklik Qeydə Alındı");
        dispatch(fetchOrders());
      }
    });
  };

  useEffect(() => {
    if (!login) {
      navigate("/login"); 
    } else {
      dispatch(fetchOrders());
    }
  }, [login, dispatch, navigate]);
  return (
    <div>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => setModalDisplay(!modalDisplay)}
          variant="contained"
        >
          Sifariş Əlavə Et
        </Button>
      </div>
      <OrderSelect orders={orders} handleUpdateOrder={handleUpdateOrder} />
      <OrderModal open={modalDisplay} onClose={() => setModalDisplay(false)} />
    </div>
  );
};

export default Orders;
