import { configureStore } from "@reduxjs/toolkit";
import firmList from "./slices/firmSlice";
import categories from "./slices/categorySlice"
import products from "./slices/productSlice";

import orders from "./slices/orderSlice";
import sales from "./slices/salesSlice";
import auth from "./slices/autSlice";
import { authApi } from './function/authService';

export const store = configureStore({
  reducer: {
    firmList,
    categories,
    products,
    orders,
    sales,
    auth,
    [authApi.reducerPath]: authApi.reducer,

  },
});
