import Container from "@mui/material/Container";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import ResponsiveAppBar from "./components/header";
import Detail from "./pages/detail";
import GoodsInroad from "./pages/goodsInroad";
import HomePage from "./pages/home";
import SignInSide from "./pages/login";
import Orders from "./pages/orders";
import Sales from "./pages/sales";
import Stock from "./pages/stock";


function App() {
  const [login, setLogin] = useState(false);

  return (
    <div className="App">
      <ResponsiveAppBar />
      <Container maxWidth="xl">
        <Routes history>
          <Route path="/stock" element={<Stock />} />
          <Route path="/sifarişlər" element={<Orders />} />
          <Route path="/Yolda Olan Stock" element={<GoodsInroad />} />
          <Route path="/Satış" element={<Sales />} />
          <Route path="/detail/:id" element={<Detail />} />
          <Route path="/məhsullar" element={<HomePage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<SignInSide login={setLogin} />} />
        </Routes>
      </Container>
    </div>
  );
}

export default App;
