import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import config from "../../api/config";

const backendURL = config.backendUrl;



export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ userName, password }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${backendURL}/account/login`,
        { userName, password },
      );
      if (data) {
        localStorage.setItem('userToken', data.data);
      }
      return data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  },
);


