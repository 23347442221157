import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { LuBaby } from "react-icons/lu";
import { Link } from "react-router-dom";
import styles from "./Cart.module.scss";

import FaceIcon from "@mui/icons-material/Face";
import Face3Icon from "@mui/icons-material/Face3";
import { TbCurrencyManat } from "react-icons/tb";

export default function MediaCard({ productName, image, weight, price, id, gender, code }) {
  const productIdHandler = async (id) => {
    localStorage.setItem("productId", id);
  };
  return (
    <Card className={styles.card} sx={{ maxWidth: 345 }}>
      <Link onClick={() => productIdHandler(id)} to={`/detail/${id}`}>
        <div className={styles.imageContainer}>
          <CardMedia className={styles.image} sx={{ height: 140 }} image={image} title="green iguana" />
          <div className={styles.gender_root}>
            {gender === "forMen" && <FaceIcon />}
            {gender === "forWomen" && <Face3Icon />}
            {gender === "forChild" && <LuBaby />}
            {gender === "forAll" && (
              <>
                <FaceIcon />
                <Face3Icon />
                <LuBaby />
              </>
            )}{" "}
          </div>
        </div>
      </Link>
      <CardContent>
        <Typography className={styles.name} gutterBottom variant="h6" component="div">
          {productName}
        </Typography>{" "}
        <Typography gutterBottom variant="body1" color="text.secondary">
          Kod: <code>{code}</code>
        </Typography>
        <div className={styles.content}>
          <Typography variant="body1" color="text.secondary">
            Çəki : {weight}q
          </Typography>
          <Typography variant="body1" className={styles.price} color="text.secondary">
            {price}
            {<TbCurrencyManat />}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
