import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import config from "../../api/config";


const backendURL = config.backendUrl;

const baseQueryFn = (headers, { getState }) => {
  const token = getState().auth.userToken;
  if (token) {
    headers.set('authorization', `Bearer ${token}`);
    return headers;
  }
};

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: backendURL, prepareHeaders: baseQueryFn }),
  endpoints: (builder) => ({
    getUserDetails: builder.query({
      query: () => ({ url: '/account/login', method: 'GET' }),
    }),
  }),
});

export const { useGetUserDetailsQuery } = authApi;
