import React, { useState } from "react";
import styles from "./select.module.scss";
import { TbCurrencyManat } from "react-icons/tb";
import { BiCheckboxChecked } from "react-icons/bi";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CurrencyLiraIcon from "@mui/icons-material/CurrencyLira";
import { Button } from "@mui/material";

const OrderSelect = ({ orders, handleUpdateOrder }) => {
  const [showItems, setShowItems] = useState();

  const showOrderItems = (id) => {
    if (showItems === id) {
      setShowItems();
    } else {
      setShowItems(id);
    }
  };

  return (
    <div className={styles.root}>
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Müştərinin Adı</TableCell>
              <TableCell align="center">Əlaqə</TableCell>
              <TableCell align="center">Ünvan</TableCell>
              <TableCell align="center">Tarix</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Cəmi Məbləğ</TableCell>
              <TableCell align="center">Əməliyyat</TableCell>
              <TableCell align="center">Ətraflı</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders &&
              orders.map((order) => (
                <>
                  <TableRow key={orders.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell className={styles.rowTable}>{order.customer}</TableCell>
                    <TableCell align="center">{order.phone}</TableCell>
                    <TableCell align="center">{order.deliveryAddress}</TableCell>
                    <TableCell align="center">{order.deliveryDate}</TableCell>
                    <TableCell align="center" style={order.isDelivered ? { color: "green" } : { color: "red" }}>
                      {order.isDelivered ? "Təhvil Verilib" : "Təhvil Verilməyib"}
                    </TableCell>
                    <TableCell align="center">
                      {order.totalPrice} <TbCurrencyManat />
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        className={styles.checked}
                        disabled={order.isDelivered}
                        onClick={() => handleUpdateOrder(order.id)}
                      >
                        <BiCheckboxChecked />
                      </Button>
                    </TableCell>
                    <TableCell align="center" className={styles.show_order} onClick={() => showOrderItems(order.id)}>
                      <KeyboardArrowDownIcon
                        style={showItems === order.id ? { rotate: "360deg" } : { rotate: "180deg" }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableCell colSpan={8}>
                    <div
                      className={styles.orderProducts}
                      style={showItems === order.id ? { height: 130 * order.details.length } : { height: 0 }}
                    >
                      {order.details.map((pr) => (
                        <div key={pr.productId} className={styles.product}>
                          <div className={styles.box_img}>
                            <img src={pr.product.thumbnail} alt="" />
                            <div className={styles.box_title}>
                              <p>{pr.product.name}</p>
                            </div>
                          </div>
                          <div className={styles.box_size}>
                            <p>{pr.product.weight} qram</p>
                          </div>
                          <div className={styles.box_count}>
                            <p>{pr.quantity} ədəd</p>
                          </div>
                          <div className={styles.box_price}>
                            <p>
                              {pr.product.price} <TbCurrencyManat />
                            </p>
                          </div>
                          <div className={styles.box_price}>
                            <p>
                              {pr.product.priceOnFirm} <CurrencyLiraIcon />
                            </p>
                          </div>
                        </div>
                      ))}
                      {/* <div className="order_data my-2 d-flex justify-content-between">
                      <div className="left">
                        <h6>Full Name: {pr.createdBy}</h6>
                        <h6>Country: {item.country}</h6>
                      </div>
                      <div className="right">
                        <h6>Email: {pr.email}</h6>
                        <h6>Phone: {pr.phone}</h6>
                      </div>
                    </div> */}
                    </div>
                  </TableCell>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OrderSelect;
