import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../api/config";

const backendURL = config.backendUrl;

export const fetchFirmList = createAsyncThunk("firmList", async () => {
  const { data } = await axios.get(`${backendURL}/Firm`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});

const initialState = {
  firmList: [],
  status: "loading",
};

const firmListSlice = createSlice({
  name: "firm",
  initialState,

  extraReducers: {
    [fetchFirmList.pending]: (state, action) => {
      state.status = "loading";
      state.firmList = [];
    },
    [fetchFirmList.fulfilled]: (state, action) => {
      state.status = "success";
      state.firmList = action.payload;
    },
    [fetchFirmList.rejected]: (state, action) => {
      state.status = "error";
      state.firmList = [];
    },
  },
});

export const {} = firmListSlice.actions;
export default firmListSlice.reducer;
