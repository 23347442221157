import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../api/config";

const backendURL = config.backendUrl;

export const fetchOrders = createAsyncThunk("orders", async () => {
  let url = `${backendURL}/order`;
  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});
export const updateOrderStatus = createAsyncThunk("update", async (id) => {
  let url = `${backendURL}/order/${id}/delivered`;
  const { data } = await axios.put(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});

export const fetchCreateOrder = createAsyncThunk("create", async (formData) => {
  let url = `${backendURL}/order`;
  const { data } = await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data;
});

const initialState = {
  orders: null,
  status: "loading",
  error: "",
};

const orderSlice = createSlice({
  name: "orders",
  initialState,

  extraReducers: {
    [fetchOrders.pending]: (state) => {
      state.status = "loading";
      state.orders = [];
    },
    [fetchOrders.fulfilled]: (state, action) => {
      state.status = "success";
      state.orders = action.payload;
      state.error = "";
    },
    [fetchOrders.rejected]: (state, action) => {
      state.status = "error";
      state.orders = [];
      state.error = action.error.message;
    },
    [updateOrderStatus.pending]: (state) => {
      state.status = "loading";
    },
    [updateOrderStatus.fulfilled]: (state) => {
      state.status = "success";
      state.error = "";
    },
    [updateOrderStatus.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
    },
  },
});

export const {} = orderSlice.actions;
export default orderSlice.reducer;
