import { Field, Form } from "react-final-form";
import styles from "./modal.module.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { Grid, TextField } from "@mui/material";
import config from "../../../../api/config";
import { Swal } from "sweetalert2";
import axios from "axios";
import { useEffect } from "react";
import { fetchProducts, fetchStock } from "../../../../redux/slices/productSlice";

const Modal = ({ open = false, onClose, product = null }) => {
  const token = window.localStorage.getItem("userToken");
  const backendURL = config.backendUrl;
  const Swal = require("sweetalert2");

  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  console.log("🚀 ~ file: index.jsx:23 ~ Modal ~ products:", products);

  useEffect(() => {
    dispatch(fetchProducts({ id: null, name: "" }));
  }, []);

  const handleSubmit = async (data) => {
    try {
      const res = await axios.post(`${backendURL}/Stock`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      onClose();
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Stok Əlavə edildi `,
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(fetchStock());
    } catch (error) {
      console.log(error);
    }
  };

  const { firmList } = useSelector((state) => state.firmList);

  return (
    <div className={styles.wrapper} style={open === false ? { top: "-100%", transition: "ease-in-out 0.5s" } : {}}>
      <div className="modal">
        <Form
          onSubmit={handleSubmit}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1} justifyContent="space-between">
                <div className={styles.close_root}>
                  <Button variant="contained" className={styles.closeBtn} onClick={() => onClose()}>
                    <AiOutlineClose />
                  </Button>
                </div>
                <Grid item xl={12} xs={12}>
                  <h3>Məhsul</h3>
                  <Field name="productId">
                    {({ input }) => (
                      <Select
                        required
                        id="productId"
                        name="productId"
                        options={products.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        placeholder="Məhsulun Adı"
                        value={input.value.value}
                        onChange={(e) => input.onChange(e.value)}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xl={12} xs={12}>
                  <h3>Sayı</h3>
                  <Field
                    required
                    name="initialAmount"
                    defaultValue={product?.name}
                    component="input"
                    type="number"
                    placeholder="Məhsulun Sayı"
                  />
                </Grid>
                <Grid container spacing={0} className={styles.submite}>
                  <Button variant="outlined" startIcon={<DeleteIcon />} onClick={form.reset}>
                    Təmizlə
                  </Button>
                  <Button type="submit" variant="contained" endIcon={<SendIcon />}>
                    Əlavə Et
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default Modal;
