import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchStock } from "../../redux/slices/productSlice";
import Modal from "./components/modal";
import StockTable from "./components/table";
import styles from "./stock.module.scss";
import { Token } from "@mui/icons-material";

const Stock = () => {
  const dispatch = useDispatch();
  const [modalDisplay, setModalDisplay] = useState(false);

  const { stockProducts } = useSelector((state) => state.products);
  const { login, token } = useSelector((state) => state.auth);
  console.log("🚀 ~ file: index.jsx:17 ~ Stock ~ token:", token)
  const navigate = useNavigate();

  useEffect(() => {
    if (login && token) {
      dispatch(fetchStock());
    } else {
      navigate("/login");
    }
  }, [login]);

  const handleClose = () => {
    setModalDisplay(false);
  };

  return (
    <div className={styles.root}>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => setModalDisplay(!modalDisplay)}
          variant="contained"
        >
          Yeni Stok Yarat
        </Button>
      </div>
      {stockProducts && <StockTable products={stockProducts} />}
      <Modal open={modalDisplay} onClose={handleClose} />
    </div>
  );
};

export default Stock;
