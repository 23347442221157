import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../api/config";

const backendURL = config.backendUrl;

export const fetchCategory = createAsyncThunk("categories", async () => {
  const { data } = await axios.get(`${backendURL}/Product/category`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});

const initialState = {
  categories: [],
  status: "loading",
};

const categoryListSlice = createSlice({
  name: "categories",
  initialState,

  extraReducers: {
    [fetchCategory.pending]: (state, action) => {
      state.status = "loading";
      state.categories = [];
    },
    [fetchCategory.fulfilled]: (state, action) => {
      state.status = "success";
      state.categories = action.payload;
    },
    [fetchCategory.rejected]: (state, action) => {
      state.status = "error";
      state.categories = [];
    },
  },
});

export const {} = categoryListSlice.actions;
export default categoryListSlice.reducer;
