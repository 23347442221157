import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../api/config";

const backendURL = config.backendUrl;

export const fetchSales = createAsyncThunk("sales", async () => {
  let url = `${backendURL}/sales`;
  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});
export const fetchSalesAmount = createAsyncThunk("salesAmount", async () => {
  let url = `${backendURL}/sales/amount`;
  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});
export const fetchCreateSales = createAsyncThunk("createSales", async (formData) => {
  let url = `${backendURL}/sales`;
  const { data } = await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});

const initialState = {
  sales: null,
  status: "loading",
  amount: null,
  error: "",
};

const salesSlice = createSlice({
  name: "sales",
  initialState,

  extraReducers: {
    [fetchSales.pending]: (state) => {
      state.status = "loading";
      state.sales = [];
    },
    [fetchSales.fulfilled]: (state, action) => {
      state.status = "success";
      state.sales = action.payload;
      state.error = "";
    },
    [fetchSales.rejected]: (state, action) => {
      state.status = "error";
      state.sales = [];
      state.error = action.error.message;
    },
    [fetchCreateSales.pending]: (state) => {
      state.status = "loading";
    },
    [fetchCreateSales.fulfilled]: (state) => {
      state.error = "";
      state.status = "success";
    },
    [fetchCreateSales.rejected]: (state, action) => {
      state.status = "error";
      state.sales = [];
      state.error = action.error.message;
    },
    [fetchSalesAmount.pending]: (state) => {
      state.status = "loading";
    },
    [fetchSalesAmount.fulfilled]: (state, action) => {
      state.status = "success";
      state.amount = action.payload;
      state.error = ""

    },
    [fetchSalesAmount.rejected]: (state, action) => {
      state.status = "error";
      state.sales = [];
      state.error = action.error.message;
    },
  },
});

export const {} = salesSlice.actions;
export default salesSlice.reducer;
