import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import BasicCard from "../../components/card";
import Modal from "../../components/modalForm";
import { fetchFirmList } from "../../redux/slices/firmSlice";
import { fetchProducts } from "../../redux/slices/productSlice";
import MobSelect from "./components/select";
import styles from "./homePage.module.scss";
import { fetchCategory } from "../../redux/slices/categorySlice";

const HomePage = () => {
  const [modalDisplay, setModalDisplay] = useState(false);

  const dispatch = useDispatch();
  const token = localStorage.getItem("userToken");
  const firmId = localStorage.getItem("firmId");
  const categoryId = localStorage.getItem("categoryId");

  const { firmList } = useSelector((state) => state.firmList);
  const { categories } = useSelector((state) => state.categories);
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(fetchProducts({ id: null, name: "" }));
    dispatch(fetchFirmList());
    dispatch(fetchCategory());
  }, []);

  const handleClose = () => {
    setModalDisplay(false);
  };
  const handlefilter = (id, title) => {
    if (title === "Firma") {
      dispatch(
        fetchProducts({
          id,
          categoryId: categoryId ? categoryId : null,
        })
      );
      localStorage.setItem("firmId", id);
    } else {
      dispatch(
        fetchProducts({
          id: firmId ? firmId : null,
          categoryId: id,
        })
      );
      localStorage.setItem("categoryId", id);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        marginTop={2}
        justifyContent="end"
        className={styles.search_root}
      >
        <Grid item xs={12} xl={5} md={12} sx={{ marginRight: { xl: "15.7%" } }}>
          <div className={styles.search}>
            <TextField
              fullWidth
              onChange={(e) => {
                dispatch(fetchProducts({ id: null, name: e.target.value }));
              }}
              id="outlined-basic"
              label="Axtarış"
              variant="outlined"
            />
            <BiSearch />
          </div>
        </Grid>

        {token && (
          <>
            <Grid item xl={1} md={12} xs={12}>
              <Button
                variant="contained"
                fullWidth
                style={{ marginRight: "10px" }}
              >
                <a
                  href="https://api.yummyfoods.az/api/v1/Product/export"
                  style={{ color: "#ffffff" }}
                >
                  YUKLE
                </a>
              </Button>
            </Grid>
            <Grid item xl={2} md={12} xs={12}>
              <Button
                fullWidth
                onClick={() => setModalDisplay(!modalDisplay)}
                variant="contained"
              >
                Yeni Məhsul Yarat
              </Button>
            </Grid>
          </>
        )}
        <div className={styles.buttonContainer}></div>
      </Grid>
      <div className={styles.root}>
        <Grid
          container
          spacing={1}
          marginTop={2}
          width="20%"
          className={styles.wrapper}
          sx={{ display: { xs: "none", xl: "block" } }}
        >
          <Grid item xs={12} xl={12} className={styles.firmContainer}>
            <Typography variant="h5" fontWeight="bold" textAlign="start">
              Firmalar
            </Typography>
            <ul>
            <li onClick={() => handlefilter(null, "Firma")}>
                Bütün Məhsullar
              </li>
              {firmList &&
                firmList.map((obj) => (
                  <li
                    key={obj.id}
                    onClick={() => handlefilter(obj.id, "Firma")}
                  >
                    {obj.name}
                  </li>
                ))}
            </ul>
            <Typography
              variant="h5"
              fontWeight="bold"
              marginTop="30px"
              textAlign="start"
            >
              Kateqorialar
            </Typography>
            <ul>
              <li onClick={() => handlefilter(null, "category")}>
                Bütün Məhsullar
              </li>
              {categories &&
                categories.map((obj) => (
                  <li
                    key={obj.id}
                    onClick={() => handlefilter(obj.id, "category")}
                  >
                    {obj.name}
                  </li>
                ))}
            </ul>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid
            container
            marginTop={3}
            sx={{ display: { xl: "none", xs: "block" } }}
          >
            <Grid>
              <MobSelect data={firmList} title={"Firma"} />
            </Grid>
            <Grid marginTop={2}>
              <MobSelect data={categories} title={"Kategoriya"} />
            </Grid>
          </Grid>
          {products &&
            products.map((item, index) => (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={item.id}>
                <BasicCard
                  id={item.id}
                  price={item.price}
                  productName={item.name}
                  weight={item.weight}
                  image={item.thumbnail}
                  gender={item.canUsed}
                  code={item.code}
                />
              </Grid>
            ))}
        </Grid>
      </div>
      <Modal open={modalDisplay} onClose={handleClose} />
    </>
  );
};

export default HomePage;
