import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../api/config";

const backendURL = config.backendUrl;

export const fetchProducts = createAsyncThunk("products", async ({ id, name ,categoryId}) => {
  let url = `${backendURL}/product`;

  if(categoryId || id){
    url += `?FirmId=${id ? id :"null"}&CategoryId=${categoryId}`
  }
  if (name) {
    url += `${id ? "&" : "?"}Name=${name.toString()}`;
  }
  const { data } = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});

export const fetchCreateProduct = createAsyncThunk("createProduct", async (formData) => {
  const { data } = await axios.post(`${backendURL}/Product`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});
export const fetchUpdateProduct = createAsyncThunk("updateProduct", async ({ id, formData }) => {
  const { data } = await axios.put(`${backendURL}/Product/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});

export const fetchStock = createAsyncThunk("stocks", async () => {
  const { data } = await axios.get(`${backendURL}/stock`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    },
  });
  return data.data;
});

const initialState = {
  products: [],
  stockProducts: [],
  status: "loading",
  error: "",
  firmId: null
};

const productSlice = createSlice({
  name: "products",
  initialState,

  extraReducers: {
    [fetchProducts.pending]: (state, action) => {
      state.status = "loading";
      state.products = [];
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.status = "success";
      state.products = action.payload;
      state.error = "";
    },
    [fetchProducts.rejected]: (state, action) => {
      state.status = "error";
      state.products = [];
      state.error = action.error.message;
    },
    [fetchStock.pending]: (state, action) => {
      state.status = "loading";
      state.stockProducts = [];
    },
    [fetchStock.fulfilled]: (state, action) => {
      state.status = "success";
      state.stockProducts = action.payload;
      state.error = "";
    },
    [fetchStock.rejected]: (state, action) => {
      state.status = "error";
      state.stockProducts = [];
      state.error = action.error.message;
    },
    [fetchCreateProduct.pending]: (state) => {
      state.status = "loading";
    },
    [fetchCreateProduct.fulfilled]: (state) => {
      state.status = "success";
      state.error = "";
    },
    [fetchCreateProduct.rejected]: (state, action) => {
      state.status = "error";
      state.error = action.error.message;
    },
  },
});

export const {} = productSlice.actions;
export default productSlice.reducer;
