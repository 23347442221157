import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styles from "./stockTable.module.scss";
import { GrStatusGoodSmall } from "react-icons/gr";
import { TbCurrencyManat } from "react-icons/tb";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { GrUpdate } from "react-icons/gr";
import CurrencyLiraIcon from "@mui/icons-material/CurrencyLira";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchStock } from "../../../../redux/slices/productSlice";
import config from "../../../../api/config";

export default function StockTable({ products }) {
  const dispatch = useDispatch();
  const backendURL = config.backendUrl;
  const token = localStorage.getItem("userToken");

  const handleDeleteStock = async (id) => {
    return Swal.fire({
      title: "Silmək istədiyinizə əminsizmi?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Təstiqlə!",
      cancelButtonText: "Ləğv Et",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios.delete(`${backendURL}/stock/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(() => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            dispatch(fetchStock());
          });
        } catch (error) {
          Swal.fire("Error", "An error occurred while deleting.", "error");
        }
      }
    });
  };

  const handleUpdateStock = (id) => {
    Swal.fire({
      title: "Sayı daxil edin",
      input: "number",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Təsdiqlə",
      cancelButtonText: "Ləğv Et",
      showLoaderOnConfirm: true,
      preConfirm: async (count) => {
        axios.defaults.headers.common["Authorization"] = token;

        return axios
          .put(`${backendURL}/stock/${id}`, { count }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(response.statusText);
            }
            dispatch(fetchStock());
            return response.data;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Əməliyyat Uğurla Tamamlandı`,
        });
      }
    });
  };

  return (
    <TableContainer component={Paper} className={styles.root}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Şəkil</TableCell>
            <TableCell>Malın Adı</TableCell>
            <TableCell>Satış Qiyməti</TableCell>
            <TableCell>Allış Qiyməti</TableCell>
            <TableCell>Malın Çəkisi&nbsp;(g)</TableCell>
            <TableCell>Stok Sayı</TableCell>
            <TableCell>Qalan Say</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Redatktə</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((item) => (
            <TableRow key={item.productId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row" className={styles.image}>
                <img src={item.product.thumbnail} alt="photo" />
              </TableCell>
              <TableCell>{item.product.name}</TableCell>
              <TableCell>
                {item.product.price}
                <TbCurrencyManat />
              </TableCell>
              <TableCell>
                {item.product.priceOnFirm}
                <CurrencyLiraIcon />
              </TableCell>
              <TableCell>{item.product.weight}</TableCell>
              <TableCell>{item.initialAmount}</TableCell>
              <TableCell>{item.currentAmount}</TableCell>
              <TableCell
                style={
                  item.isEmpty === true ? { color: "red" } : item.runningOut ? { color: "#eb9334" } : { color: "green" }
                }
              >
                <GrStatusGoodSmall />
              </TableCell>
              <TableCell className={styles.buttonContainer}>
                <button onClick={() => handleUpdateStock(item.id)}>
                  <GrUpdate />
                </button>
                <button onClick={() => handleDeleteStock(item.id)}>
                  <RiDeleteBin6Fill style={{ color: "red" }} />
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
