import { Button, Container } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { RxUpdate } from "react-icons/rx";
import { TbCurrencyManat } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../api/config";
import Modal from "../../components/modalForm";
import { fetchProducts } from "../../redux/slices/productSlice";
import styles from "./detail.module.scss";

const Detail = () => {
  const backendUrl = config.backendUrl;
  const token = localStorage.getItem("userToken");

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [product, setProduct] = useState({});
  console.log("🚀 ~ file: index.jsx:28 ~ Detail ~ product:", product)
  const [modalDisplay, setModalDisplay] = useState(false);

  const Swal = require("sweetalert2");

  const handleClose = () => {
    setModalDisplay(false);
  };
  const handlerDelete = async (id) => {
    return Swal.fire({
      title: "Silmək istədiyinizə əminsizmi?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Təstiqlə!",
      cancelButtonText: "Ləğv Et",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          axios
            .delete(`${backendUrl}/product/${id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(async () => {
              await Swal.fire("Silindi!", "Məhsul müvəffəqiətlə silindi");
            });
          dispatch(fetchProducts({ id: null, name: "" }));
          navigate("/");
        } catch (error) {
          Swal.fire("Error", "An error occurred while deleting.", "error");
        }
      }
    });
  };

  const handlerModal = () => {
    setModalDisplay(true);
  };

  const fetchProduct = async () => {
    try {
      const { data } = await axios.get(`${backendUrl}/Product/${id}`);
      setProduct(data.data);
    } catch (error) {
      alert("Datada Sehv");
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <Box sx={{ marginTop: 8 }}>
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            minHeight={600}
            sx={{
              backgroundImage: `url(${product.photo})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              className={styles.box}
              maxHeight={500}
              overflow="scroll"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid container justifyContent="space-between">
                <Grid item md={12}>
                  <Typography component="h3" variant="h5" fontWeight="bold">
                    {product.name}
                  </Typography>
                </Grid>
                <Grid container justifyContent="space-between" marginTop={2}>
                  <Grid item md={4}>
                    <Typography variant="h6">Çəki: {product.weight}q</Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="h6" className={styles.price}>
                      {product.price}
                      <TbCurrencyManat />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item md={12}>
                  <Typography marginTop={5} variant="body1">
                    <span style={{ fontWeight: "bold" }}>Təsviri:</span>
                    <br />
                    {product.description}
                  </Typography>
                </Grid>

                <Grid item md={12}>
                  <Typography marginTop={2} variant="body1">
                    <span style={{ fontWeight: "bold" }}>Saxlanma Şərtləri:</span>
                    <br />

                    {product.storageCondition}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {token && (
              <Grid container justifyContent="space-between" marginBottom={2} spacing={1} padding={2}>
                <Grid item xs={12} md={5} xl={5}>
                  <Button fullWidth color="warning" onClick={() => handlerModal()} variant="contained">
                    <RxUpdate />
                    Yenile
                  </Button>
                </Grid>
                <Grid item xs={12} md={5} xl={5}>
                  <Button fullWidth color="error" variant="contained" onClick={() => handlerDelete(product.id)}>
                    <RiDeleteBin5Line /> Sil
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>

      <Modal open={modalDisplay} onClose={handleClose} product={product} />
    </Container>
  );
};

export default Detail;
