import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSales, fetchSalesAmount } from '../../redux/slices/salesSlice';
import SalesModal from './components/salesModal';
import SalesSelect from './components/salesSelect';
import styles from './sales.module.scss';
import { TbCurrencyManat } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const Sales = () => {
	const [modalDisplay, setModalDisplay] = useState(false);

	const dispatch = useDispatch();

	const { login, token } = useSelector(state => state.auth);

	const { sales, amount } = useSelector(state => state.sales);

	const navigate = useNavigate();

	useEffect(() => {
		if (login && token) {
			dispatch(fetchSales());
			dispatch(fetchSalesAmount());
		} else {
			navigate('/login');
		}
	}, [login]);

	return (
		<div>
			<div className={styles.buttonContainer}>
				<Grid gap={1} container justifyContent='space-between'>
					<Grid
						style={{ border: '1px solid #1976d2' }}
						item
						xl={3}
						md={3}
						xs={12}
						textAlign='center'
						color='#1976D2'
					>
						<h3>Cari Günlük</h3>
						<div className={styles.miniContainer}>
							<div className={styles.amountSales}>
								<span>Satış</span>
								<p>
									{amount?.totalPriceOfDay} <TbCurrencyManat />
								</p>
							</div>
							<div className={styles.amountProfit}>
								<span>Mənfəət</span>
								<p>
									{amount?.totalProfitOfDay} <TbCurrencyManat />
								</p>
							</div>
						</div>
					</Grid>
					<Grid
						style={{ border: '1px solid #1976d2' }}
						item
						xl={3}
						md={3}
						xs={12}
						textAlign='center'
						color='#1976D2'
					>
						<h3>Cari Həftəlik </h3>
						<div className={styles.miniContainer}>
							<div className={styles.amountSales}>
								<span>Satış</span>
								<p>
									{amount?.totalPriceOfWeek} <TbCurrencyManat />
								</p>
							</div>
							<div className={styles.amountProfit}>
								<span>Mənfəət</span>
								<p>
									{amount?.totalProfitOfWeek} <TbCurrencyManat />
								</p>
							</div>
						</div>
					</Grid>
					<Grid
						style={{ border: '1px solid #1976d2' }}
						item
						xl={3}
						md={3}
						xs={12}
						textAlign='center'
						color='#1976D2'
					>
						<h3>Cari Aylıq </h3>
						<div className={styles.miniContainer}>
							<div className={styles.amountSales}>
								<span>Satış</span>
								<p>
									{amount?.totalPriceOfMonth} <TbCurrencyManat />
								</p>
							</div>
							<div className={styles.amountProfit}>
								<span>Mənfəət</span>
								<p>
									{amount?.totalProfitOfMonth} <TbCurrencyManat />
								</p>
							</div>
						</div>
					</Grid>
					<Grid item xl={2} md={2} xs={12} color='#1976D2'>
						<Button fullWidth onClick={() => setModalDisplay(!modalDisplay)} variant='contained'>
							Satiş Əlavə Et
						</Button>
					</Grid>
				</Grid>
			</div>
			<SalesSelect sales={sales} />
			<SalesModal open={modalDisplay} onClose={() => setModalDisplay(false)} />
		</div>
	);
};

export default Sales;
